import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';

export const StyledDefaultPage = styled.div`
    ${generateGrid(24, 8)}
`;

export const ContentWrapper = styled.div`
    ${generateCol(24)}
    padding: 0 12px;
    padding-top: 24px;
    padding-bottom: 48px;

    ${generateBreakpoint('md')(
        `padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        padding-bottom: 64px;
        ${generateCol(20, 3)}`,
    )}
    ${generateBreakpoint('lg')(
        `padding-top: 64px;
        padding-bottom: 128px;
        ${generateCol(16, 5)}`,
    )}
    ${generateBreakpoint('xl')(generateCol(12, 7))}
    ${generateBreakpoint('xxl')(generateCol(10, 8))}

    color: ${colors.veryLightPink};
`;

export const StyledTitle = styled.div`
    h1 {
        font-size: 40px;
        line-height: 1.1;
        letter-spacing: -1.2px;
        text-transform: uppercase;
    }
    padding-bottom: 24px;

    ${generateBreakpoint('md')('padding-bottom: 32px;')}
    ${generateBreakpoint('lg')('padding-bottom: 48px;')}
`;

export const SectionTitle = styled.div`
    h3 {
        font-size: 32px;
        line-height: 1.1;
        letter-spacing: -1.2px;
        text-transform: uppercase;
    }

    padding-bottom: 16px;

    ${generateBreakpoint('md')('padding-bottom: 24px;')}
    ${generateBreakpoint('lg')('padding-bottom: 30px;')}
`;

export const Content = styled.div`
    font-family: 'SuisseIntl';
    font-size: 19px;
    line-height: 1.47;
    letter-spacing: 0.29px;

    padding-bottom: 32px;

    ul {
        margin: 8px; 0;
        margin-left: 20px;
    }

    h4 {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: -1.2px;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 1em;
    }

    ${generateBreakpoint('md')('padding-bottom: 48px;')}
    ${generateBreakpoint('lg')('padding-bottom: 64px;')}
`;
