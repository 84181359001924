import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from '@components/shared';
import {
    StyledDefaultPage,
    ContentWrapper,
    StyledTitle,
    SectionTitle,
    Content,
} from './default.styled';

const DefaultPage = ({ data, pageContext }) => {
    if (!data) {
        return null;
    }

    const {
        prismic: {
            page: { title, content, sections },
        },
    } = data;

    return (
        <StyledDefaultPage>
            <ContentWrapper>
                {title && title.length ? (
                    <StyledTitle>
                        <RichText render={title} />
                    </StyledTitle>
                ) : null}
                {content && content.length ? (
                    <Content>
                        <RichText render={content} />
                    </Content>
                ) : null}
                {sections && sections.length
                    ? sections.map((s, i) => (
                          <section key={`section_${i}`}>
                              {s.section_title && s.section_title.length ? (
                                  <SectionTitle key={`section_title_${i}`}>
                                      <RichText render={s.section_title} />
                                  </SectionTitle>
                              ) : null}
                              {s.section_content && s.section_content.length ? (
                                  <Content key={`section_content_${i}`}>
                                      <RichText render={s.section_content} />
                                  </Content>
                              ) : null}
                          </section>
                      ))
                    : null}
            </ContentWrapper>
        </StyledDefaultPage>
    );
};

export const query = graphql`
    query GetPageQuery($uid: String!) {
        prismic {
            page(lang: "en-au", uid: $uid) {
                title
                sections {
                    section_content
                    section_title
                }
                content
            }
        }
    }
`;

export default DefaultPage;
